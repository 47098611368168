import React from 'react'
import Checkout from '../components/checkout/Checkout'
import { Layout } from '../components/layout'

const CheckoutContainer: React.FC = () => {
  return (
    <Layout>
      <Checkout />
    </Layout>
  )
}

export default CheckoutContainer
