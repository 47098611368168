import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Review from './Review'
import { connect } from 'react-redux'
import CheckoutStripe, { LineItem } from '../strip-checkout/Checkout'

interface StateToProps {
  cart: any[]
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

const Checkout: React.FC<StateToProps> = ({ cart }) => {
  const classes = useStyles()
  const [lineItems, updateLineItems] = React.useState<LineItem[]>([])

  // format cart to pass to stripe checkout
  React.useEffect(() => {
    console.log('cart', cart)
    const lineItemsTemp = cart.map(c => {
      return { price: c.priceId, quantity: c.quantity }
    })
    updateLineItems(lineItemsTemp)
  }, [cart])

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h1" align="center">
            Cart
          </Typography>
          <React.Fragment>
            <React.Fragment>
              <Review cart={cart} />
              <div className={classes.buttons}>
                <CheckoutStripe lineItems={lineItems} buttonText={'checkout'} />
              </div>
            </React.Fragment>
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  )
}

export default connect<StateToProps>(
  (state: any) => ({
    cart: state.app.cart,
  }),
  (dispatch: any) => ({})
)(Checkout)
