import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'

interface Props {
  cart: any
}

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}))

const Review: React.FC<Props> = ({ cart }) => {
  const classes = useStyles()

  const [totalPrice, updateTotalPrice] = React.useState(0)

  React.useEffect(() => {
    if (cart.length > 0) {
      let price = 0
      cart.forEach((item: { price: number }) => {
        price += item.price
      })
      updateTotalPrice(price)
    }
  }, [cart])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {cart.map((product: any) => (
          <ListItem className={classes.listItem} key={product.priceId}>
            <img
              src={product.image}
              alt={'product'}
              style={{ height: '40px', marginRight: '10px' }}
            />
            <ListItemText
              primary={product.description}
              secondary={`Size: ${product.size}`}
            />
            <Typography variant="body2">${product.price}</Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            ${totalPrice}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}></Grid>
    </React.Fragment>
  )
}

export default Review
